<template>
  <div>
    <!-- <el-card shadow="never"> -->
    <el-form :inline="true" :model="searchForm">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-form-item label="学员姓名：">
              <el-input
                clearable
                v-model="searchForm.userName"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="课程名称：">
              <el-select
                filterable
                clearable
                :loading="false"
                v-model="searchForm.courseName"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in courseOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="课时类型：">
              <el-select v-model="searchForm.courseGoodsName" placeholder="请选择课时类型">
                <el-option
                  v-for="item in select"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="消费类型：">
              <el-select
                clearable
                v-model="searchForm.flag"
                placeholder="消费类型"
                class="w100"
              >
                <el-option
                  v-for="item in options"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-form-item>
            <el-button type="primary" @click="getListHandlers">查询</el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-row>
      </div>
    </el-form>
    <!-- 表格 -->
    <StudentCourseLogList ref="detail" :searchForm="searchForm"/>
  </div>
</template>
<script>
import StudentCourseLogList from "./components/StudentCourseLogLists";
import { getGoodsList } from "@/api/businesscourse/index";
import {
  getListCourseOnly as getCourseList,
} from "@/api/businesscourse/course";

export default {
  components: { StudentCourseLogList },
  data() {
    return {
      select:[],
      courseOptions: [],
      size: "default",
      searchForm: {
        userName: null,
        courseGoodsName: null,
        courseName: null,
        flag: null,
      },
      statusOptions: {
        报名: "报名",
        消课: "消课",
        退课: "退课",
      },
      options: [
         {
          value: "报名",
          label: "报名",
        },
        {
          value: "消课",
          label: "消课",
        },
        {
          value: "退课",
          label: "退课",
        },
      ],

    };
  },
  watch:{
    '$route.params':{
      handler(val){
        if(val.userName)
        this.searchForm.userName = val.userName;
        this.$nextTick(()=>{
          this.getListHandlers();
        })
      },
      deep: true,
      immediate: true,
    }
  },
  mounted() {
    this.getList();
    this.getCourseList();
  },

  methods: {
    getCourseList() {
      getCourseList({
        needCount: true,
        pageNum: 1,
        pageSize: 1000,
      }).then((res) => {
        this.courseOptions = res.body.list.map((i) => ({
          value: i.title,
          label: i.title,
        }));
      });
    },
    getList() {
      getGoodsList().then((res) => {
        if (res.state == "success") {
          this.select = res.body.list.map((i) => ({
            value: i.courseGoodsName,
          }));
        }
      });
    },
    getListHandlers() {
      this.$refs.detail.initData();
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.searchForm = {
        userName: null,
        courseGoodsName: null,
        courseName: null,
        flag: null,
      };
      this.getListHandlers();
    },

  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}
@import "../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>
